import Vue from 'vue'
import Router from 'vue-router'
 
//组件模块
import Index from './view/index.vue'
 
Vue.use(Router)
 
export default new Router({
  routes: [
    { path: '/', name: 'index', component: Index },
  ]
})
const XXTEA = require('./xxtea.js')
export function getToken(time) {
  const secretKey = '*￥#64)$#24^$%35&'
  let getToken = '_appid=22&_appversion=1.0.0&_accesstoken=0&_requesttime=' + time + '&_uid=0&_did=0'
  let finalStr = ''
  finalStr = XXTEA.encryptToBase64(getToken, secretKey).replace(/\+/g,'-')
  finalStr = finalStr.replace(/\//g,'_')
  finalStr = finalStr.replace(/\=/g,'.')
  return finalStr
}

<template>
  <div>
    <!-- <div class="pages-home" v-if="!isFixed"> -->
    <div class="pages-home">
      <wc-swiper v-if="resultObj.images.substr(0, 1) == '['">
        <wc-slide v-for="(slide, key) in JSON.parse(resultObj.images)" :key="key" @click="$preview($event, JSON.parse(resultObj.images), 0, 'url')">
          <div style="width: 100%; height: 200px;"><img :src="slide.url" width='100%'/></div>
        </wc-slide>
      </wc-swiper>
      <div class="title">{{resultObj.title}}</div>
      <div class="desc"><span class="left">{{resultObj.title}}</span><span class="right">营业时间{{resultObj.business_time}}</span></div>
      <div class="location" @click="goToDown"><i class="location-img"></i><a>{{resultObj.location}}</a></div>
      <ul class="activity">
        <li v-for="(active, key) in resultObj.activity" :key="key">
          <span class="logo">{{active.tag}}</span>
          <span class="content">{{active.content}}</span>
        </li>
      </ul>
      <div class="box" v-if="zpObj.list.length > 0" @click="goToDown">
        <span class="logo">招聘</span>
        <div class="marquee">
          <ul class="zhaopin marquee_list">
            <li v-for="(active, key) in zpObj.list" :key="key" :class="{marquee_top:animate}">
              <span class="content">{{active.post}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="phone-icon"><a :href="phoneNum"><img style="width: 30px" src="../assets/phone.png"></a></div>
    </div>
    <div v-if="resultObj.package.length > 0" :class="[isFixed ? 'is-Fixed goods' : 'no-fixed goods']" id='goods'>
      <div  v-bind:style="{ height: height + 'px' }">
        <ul class="left">
          <li v-for="(good, key) in resultObj.goods" :key="key" :class="{active: key ===  currentIndex}" @click="goToClick(key)">{{good.name}}</li>
        </ul>
        <ul class="right" id="right" ref="right">
          <li v-for="(good, key) in resultObj.goods" :key="key" @click="showDia(key)">
            <div :id="key" class="name">{{good.name}}</div>
            <div class="good-area" v-for="(list, key1) in good.list" :key="key1">
              <div class="inner-left" v-if="list.images !== '' && list.images !== undefined">
                <!-- <img :src="JSON.parse(list.images)[0].url"> -->
                <div class="img" :style="{backgroundImage: 'url(' + JSON.parse(list.images)[0].url + ')'}" @click="$preview($event, JSON.parse(list.images), 0, 'url')">
                  <i v-if="JSON.parse(list.images).length > 1">{{JSON.parse(list.images).length}}</i>
                </div>
              </div>
              <div class="inner-right">
                <div class="inner-name">{{list.name}}</div>
                <div v-if="list.desc" class="list-desc">
                  {{list.desc}}
                </div>
                <div class="inner-usetime">{{list.usetime}}</div>
                <!-- {{list.goods}} -->
                <div v-if="list.goods !== '' && list.goods !== undefined" class="inner-desc-outer">
                  <div v-for="(innerList, key2) in JSON.parse(list.goods)" :key='key2' class="inner-desc">
                    <span v-for="(innergood, key3) in innerList.goods" :key="key3">
                      {{innergood.name}}({{innergood.num}}份)
                    </span>
                  </div>
                </div>
                <div v-else class="inner-desc-outer-height"></div>
                <div class="inner-price" v-if="list.price">¥{{list.price}}<i v-if="list.price === '0.00' || list.original_price !== '0.00' ">{{list.original_price}}</i></div>
                <div class="inner-original" v-else :style="{marginTop: (list.images !== '' && list.images !== undefined) ? '11px' : '0'}">¥{{list.discount_price}}<i>{{list.original_price}}</i></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-else style="margin-top: 40px;margin-left: 10px;">老板很懒，还没有布置店铺哦</div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="300px">
      <img src="../assets/400.png" style="width: 30px;border-radius: 5px;vertical-align: middle;margin-right: 5px;"><span style="vertical-align: middle;">下载智慧淅川App查看更多信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="gotoHref">去下载</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import { getToken } from '../utils/func.js'
  export default {
    name: '',
    components: {

    },
    data() {
      return {
        slides: [],
        resultObj: {},
        currentIndex: -1,
        isFixed: false,
        height: 0,
        box: '',
        zpObj: {
        },
        animate: false,
        phoneNum: '',
        dialogVisible: false
      }
    },
    methods: {
      add0(m){return m<10?'0'+m:m },
      format(shijianchuo)
        {
        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(shijianchuo * 1000);
        var y = time.getFullYear();
        var m = time.getMonth()+1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y+'年'+this.add0(m)+'月'+this.add0(d)+'日 '+this.add0(h)+':'+this.add0(mm);
      },
      goToClick(key) {
        this.currentIndex = key;
        setTimeout(() => {
          document.getElementById(key).scrollIntoView(true);
        }, 5)
        if (key == 0) {
          this.dialogVisible = true
        }
      },
      showDia(key) {
        if (key == 0) {
          this.dialogVisible = true
        }
      },
      handleScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        let offsetTop = document.querySelector('#goods').offsetTop
        this.isFixed = scrollTop >= offsetTop ? true : false
      },
      showMarquee () {
        this.animate = true
        setTimeout(() => {
          this.zpObj.list.push(this.zpObj.list[0])
          this.zpObj.list.shift()
          this.animate = false
        }, 500)
      },
      goToDown() {
        this.dialogVisible = true
      },
      gotoHref() {
        this.dialogVisible = false
        window.location.href = 'http://qr16.cn/FmJ73c'
      },
      GetRequest(url) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
        }
        return(false);
      }
    },
    mounted() {
      var url33 = window.location.href;

      var querys = url33.substring(url33.indexOf('?') + 1).split('&');
      var result=[];
      for(var i=0;i<querys.length;i++){
          var temp=querys[i].split('=');
          if(temp.length<2){
              result[temp[0]]='';
          }else{
              result[temp[0]]=temp[1];
          }
      }
      console.log(result);
      var a=result['id'];

      // console.log(new Date().getTime())
      // if (1621672869000 < new Date().getTime() && Math.random()* 100 %3 < 1) {
      //   return;
      // }
      let that = this
      const url = `http://dev-api.aixichuan.cn/bm/store/detail`
      axios.get(url, 
        { params: {
          store_id: a,
          _tokendata: getToken(String(new Date().getTime()).substring(String(new Date().getTime()).length - 10, String(new Date().getTime()).length))
        }}).then(function(res){
          console.log('post请求...'); 
          console.log(res.data); 
          that.resultObj = res.data.data
          that.resultObj.goods.unshift({
            catid: '9999',
            name: '套餐',
            list: res.data.data.package
          })
          that.phoneNum = 'tel:' + res.data.data.mobile

          const url1 = `http://dev-api.aixichuan.cn/bm/recruit/position_list`
          axios.get(url1, 
            { params: {
              company_type: 1,
              company_id: res.data.data.id,
              catid: 0,
              typeid: 0,
              salary: 0, //需要一个返回全部薪资范围的id,比如0
              experience: 0, //0=不限
              page: 1, //默认1
              count: 100, //默认20
              // store_id: '16',
              _tokendata: getToken(String(new Date().getTime()).substring(String(new Date().getTime()).length - 10, String(new Date().getTime()).length))
            }}).then(function(res1){
              console.log('post请求...111'); 
              console.log(res1.data);
              that.zpObj = res1.data.data
              setInterval(that.showMarquee, 2500)
          })
      })
      


      window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
      // this.height = document.documentElement.clientHeight
      setTimeout(() => {
        this.height = document.getElementById("right").offsetHeight
      }, 500)
      // window.addEventListener('scroll', () => {
      //   this.box = this.$refs.right
      //   console.log(this.box)
      //   this.box.addEventListener('scroll', () => {
      //     console.log(this.$refs.right.scrollTop)
      //     if (this.$refs.right.scrollTop == 0) {
      //       this.isFixed = false
      //       document.documentElement.scrollTop = document.querySelector('#goods').offsetTop
      //       // window.removeEventListener('scroll', this.handleScroll)
      //     }
      //   })
      // })
      // // 通过$refs获取dom元素
      // this.$nextTick(() => {
      //   this.box = this.$refs.right
      //   console.log(this.box)
      //   this.box.addEventListener('scroll', () => {
      //     console.log(this.$refs.right.scrollTop)
      //     if (this.$refs.right.scrollTop == 0) {
      //       this.isFixed = false
      //       document.documentElement.scrollTop = document.querySelector('#goods').offsetTop
      //       // window.removeEventListener('scroll', this.handleScroll)
      //     }
      //   })
      // })
        

    }
  }
</script>

<style scoped>
  .pages-home {
    position: relative;
    width: calc(100% - 30px);
    margin: 0 auto;
    padding: 15px 15px 5px;
    background-color: #fff;
  }
  .wc-swiper-container {
    border-radius: 5px;
    height: 194px;
  }
  .phone-icon {
    position: absolute;
    bottom: 20px;
    right: 10px;
  }
  .title {
    margin: 15px 0 12px;
    font-size: 18px;
    color: #000;
    font-weight: bold;
  }
  .desc {
    margin: 12px 0 15px;
    overflow: hidden;
  }
  .desc .left {
    float: left;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  .desc .right {
    float: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
  .location {
    margin: 15px 0 10px;
    font-size: 12px;
  }
  .location-img {
    display: inline-block;
    vertical-align: middle;
    width: 14px;
    height: 14px;
    background-image: url('../assets/location.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .location a {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    color: blue;
    text-decoration: underline;
  }
  .activity {}
  .activity li {
    margin: 10px 0 5px;
  }
  .activity .logo {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    padding: 0 5px;
    background-color: #ff6308;
    color: #fff;
    border-radius: 3px;
    height: 18px;
    line-height: 19px;
  }
  .activity .content {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
  .box {
    flex: 1;
    display: flex;
    align-items: center;
    height: 38px;
  }
  .box .marquee {
    position: relative;
    height: 50%;
    flex: 1;
    overflow: hidden;
  }
  .box .marquee .marquee_list {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
  }
  .box .marquee .marquee_list li {
    height: 100%;
  }
  .box .marquee .marquee_list li.marquee_top {
    transition: all 0.5s;
    transform: translateY(-100%);
  }
  .zhaopin {}
  .box .logo {
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    padding: 0 3px;
    background-color: #c50000;
    color: #fff;
    border-radius: 3px;
    line-height: 19px;
    height: 18px;
  }
  .zhaopin .content {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
  }
  .is-Fixed{
    /* position: fixed;
    top: 0;
    z-index: 999; */
  }
  .goods {
    /* overflow: hidden; */
    background-color: #f2f2f2;
    padding-top: 5px;
  }
  .goods .left {
    float: left;
  }
  .goods .left li {
    width: 80px;
    font-size: 13px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    background-color: #d9d9da;
  }
  .goods .left li.active {
    background-color: #fff;
  }
  .is-Fixed .right {
    /* height: 100%;
    overflow: scroll; */
  }
  .goods .right {
    float: left;
    width: calc(100% - 90px);
    padding-left: 10px;
    background-color: #fff;
    overflow: scroll;
    -webkit-overflow-scrolling : touch;

  }
  .goods .right .name {
    font-weight: bold;
    margin: 10px 0 20px;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
  }
  .goods .right .good-area {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 13px;
    overflow: hidden;
  }
  .inner-left {
    position: relative;
    float: left;
    margin-right: 12px;
    width: 95px;
    height: 95px;
  }
  .inner-left .img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
  }
  .inner-left .img i {
    position: absolute;
    left: 5px;
    bottom: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: 25px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 4px;
  }
  .inner-right .inner-name {
    line-height: 20px;
    font-weight: bold;
    margin: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.85);
  }
  .inner-right .inner-usetime {
    margin: 10px 0;
    font-size: 12px;
    color: #bbb;
  }
  .inner-desc-outer {
    margin: 10px 0;
    padding: 0 10px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .inner-desc-outer-height {
    height: 22px;
  }
  .inner-right .inner-desc {
    display: inline;
    margin: 10px 0;
    font-size: 12px;
    color: #bbb;
  }
  .inner-right .list-desc {
    margin: 6px 0 0;
    font-size: 12px;
    color: #bbb;
  }
  .inner-right .inner-price {
    font-size: 14px;
    color: #e85600;
    font-weight: 500;
  }
  .inner-right .inner-price i {
    margin-left: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);;
    text-decoration: line-through;
  }
  .inner-right .inner-original {
    font-size: 14px;
    color: #e85600;
    font-weight: 500;
  }
  .inner-right .inner-original i {
    margin-left: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);;
    text-decoration: line-through;
  }


</style>



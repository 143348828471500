import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import wcSwiper from 'wc-swiper'
import 'wc-swiper/style.css'

import wcView from 'wc-view'
import 'wc-view/style.css'

Vue.use(wcView)
Vue.use(wcSwiper)

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
